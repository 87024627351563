import { getApiWrapper, postApiWrapper } from './token-wrapper-function';

export const registrationStatus = async (id, status) => {
  const result = await postApiWrapper(`/api/registrations/${id}/status`, { 'status': status });
  return result;
};

export const registrationInfoByDate = async (startDate, endDate) => {
  const result = await getApiWrapper(`/api/registrations?start_date=${startDate}&end_date=${endDate}`);
  return result;
};

import React, { useState, useEffect } from 'react';
import { registrationInfoByDate, registrationStatus } from '../../actions/registration';
import DataTable from 'react-data-table-component';
import Col from '@paljs/ui/Col';
import LoadingSpinner from '../loader'
import { InputGroup } from '@paljs/ui/Input';
import { Button } from '@paljs/ui/Button';
//import { DateRangePicker } from 'react-date-range'
import moment from 'moment';
import Select from '@paljs/ui/Select';
import styled from 'styled-components';
import DateRangePicker from '../../components/date/date-range'
import { Link } from 'gatsby';
import { getLoggedInUser } from '../../actions/authAction';
const LINKED = 'LINKED'
const PENDING = 'OTP_PENDING'

const SelectStyled = styled(Select)`
  margin-bottom: 1rem;
  width: 8rem;
`;

export default function index() {

  const [rows, setRows] = useState([]);
  const [isLoading, showLoader] = useState(true);
  const [originalRows, setOriginalRows] = useState([]);
  const [searched, setSearched] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState('All')
  const [userVillage, setUserVillage] = useState('');
  const [ranges, setRanges] = useState([
    {
      startDate: null,
      endDate: null,
    },
  ]);
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('');
  moment.locale();
  const previousDate = moment().subtract(1, "months").format("YYYY-MM-DD");
  const currentDate = moment().format("YYYY-MM-DD");



  useEffect(() => {
    const loggedInUser = getLoggedInUser();
    dateRangeFilterData(previousDate, currentDate, loggedInUser.village);
  }, []);

  const statusOption: { value: any; label: any }[] = [
    { label: 'Linked', value: 'LINKED' },
    { label: 'Pending', value: 'OTP_PENDING' },
    { label: 'Mobile added', value: 'MOBILE_UPDATED' },
    { label: 'Village Not Found', value: 'VILLAGE_NOT_FOUND' },
  ];

  const tableColumns = [
    {
      name: 'S.No.',
      sortable: false,
      grow: 0.2,
      cell: (row, index, column, id) => {
        return (<div>{index + 1}</div>)
      }
    },
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,
      wrap: true,
      cell: (row) =>
        <>
          <div><Link to={`/users?name=${row.name}&gautra=${row.gautra}`}>{row.name} </Link> &nbsp; S/o <Link to={`/users?name=${row.fathersName}&gautra=${row.gautra}`}>{row.fathersName} </Link> &nbsp; S/o <Link to={`/users?name=${row.grandFathersName}&gautra=${row.gautra}`}>{row.grandFathersName} </Link></div>
        </>
    },
    {
      name: 'Gautra',
      selector: (row) => row.gautra,
      sortable: true,
    },
    {
      name: 'Village',
      selector: (row) => row.village,
      sortable: true,
    },
    {
      name: 'Mobile No.',
      selector: (row) => row.mobile,
      sortable: false,
      cell: (row) =>
        <>
          <div><Link to={`/users?mobile=${row.mobile}`}>{row.mobile}</Link></div>
        </>
    },
    {
      name: 'spouse Details',
      selector: (row) => row.spouse_name,
      sortable: false,
      cell: (row) =>
        <>
          {row.spouse_name ? <div><Link to={`/users?name=${row.spouse_name}&gautra=${row.spouse_gautra}`}>{row.spouse_name}</Link> &nbsp; D/o <Link to={`/users?name=${row.spouse_fathers_name}&gautra=${row.spouse_gautra}`}>{row.spouse_fathers_name}</Link> &nbsp;{row.spouse_gautra} from {row.spouse_village} </div> : null}
        </>
    },
    {
      name: 'Profile Picture',
      selector: (row) => row.profile_pic_url,
      cell: (row) =>
        <img src={row.profile_pic_url} width="100px" height="70px" border-radius='50px' />
    },
    {
      name: 'Date',
      selector: (row) => row?.createdAt,
      sortFunction: (a, b) => handleNullValues(a, b),
      cell: (row) =>
        <>
          {moment(row?.createdAt).format("YYYY-MM-DD")}
        </>
    },
    {
      name: 'Current Status',
      selector: (row) => row.status.label,
    },
    {
      name: 'change status',
      grow: 3,
      cell: (row) =>
        <>
          <SelectStyled options={statusOption} placeholder="Status" onChange={(value) => { resolve(row._id, value.value) }} value={row.status} />
        </>
    },

  ];
  const resolve = async (id: any, status: string) => {
    console.log("id and status = ", id, 'and', status)
    const result = await registrationStatus(id, status)
    console.log("result after button(mark as linked) clicked", result)
    const updatedData = originalRows.map((x) => {
      if (x['_id'] === result.data.id) {
        let status = {}
        statusOption.map((s) => {
          if (s.value == result.data.registration.otp_status) {
            status = { "value": s.value, "label": s.label }
          }
        })
        x = { ...result.data.registration, "status": status };
      }
      return x;
    });
    setOriginalRows(updatedData);
    //setRows(updatedData);
    filterRows(filter, updatedData);
  }
  // const requestSearch = (searchedVal: string) => {
  //   console.log("this is for searchva")
  //   const filteredRows = originalRows.filter((e) => {
  //     return(JSON.stringify(e).indexOf(searchedVal) >= 0)
  //   });
  //   setRows(filteredRows);
  // };

  // const cancelSearch = () => {
  //   setSearched("");
  //   requestSearch(searched);
  // }


  const handleInputChange = (event) => {
    do_search(event.target.value)
  };

  const handleNullValues = (a, b) => {
    if (a.createdAt == null && b.createdAt == null) {
      return 0;
    }
    if (a.createdAt == null) {
      return 1;
    }
    if (b.createdAt == null) {
      return -1;
    }
    if (a.createdAt > b.createdAt) {
      return -1
    } else
      return 1

  }


  const do_search = (v) => {
    const value = v.trim()
    const temp_rows = originalRows.filter((e) => JSON.stringify(e).indexOf(value) >= 0);
    setRows(temp_rows);
  };

  const dateRangeFilterData = async (startDate: any, endDate: any) => {
    try {
      const response = await registrationInfoByDate(startDate, endDate);
      const data = response.data.registrations;
      // const data = response.data.registrations.filter((item) => item.village == userVillage);
      console.log("registration's rows", data)
      data.sort((a, b) => handleNullValues(a, b))
      const data1 = data.map((item) => {
        let status = {}
        statusOption.map((s) => {
          if (s.value == item.otp_status) {
            status = { "value": item.otp_status, "label": s.label }
          }
        })
        return {
          ...item, "status": status
        }
      })
      setOriginalRows(data1);
      setRows(data1);
      showLoader(false);
      setStartDate(startDate);
      setEndDate(endDate);
    } catch (error) {
      // setError('Something went wrong!');
      showLoader(false);
    }
  };

  const dateRangeChange = (range) => {
    const changedStartDate = moment(range.startDate).format("YYYY-MM-DD");
    const changedEndDate = moment(range.endDate).format("YYYY-MM-DD");
    range.startDate !== range.endDate ? setIsOpen(!isOpen) : '';
    dateRangeFilterData(changedStartDate, changedEndDate);
  }



  const onDateRangeToggle = () => {
    setIsOpen(!isOpen);
  };

  const filterRows = (value, temp_rows = originalRows) => {
    console.log("value to filter", value)
    const filtered = temp_rows.filter((row) => row.otp_status === value);
    console.log("filtered rows", filtered);
    if (value !== 'All') {
      setRows(filtered);
    } else {
      setRows(temp_rows);
    }
  }


  const registration = (
    <>
      <>
        <Col style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Col breakPoint={{ xs: 12, md: 4 }}>
            <Button
              fullWidth
              appearance="outline"
              shape={'Round'}
              size={'Small'}
              Status={'Info'}
              onClick={onDateRangeToggle}
            >
              Select Date Range
            </Button>
          </Col>

          <select
            onChange={(event) => {
              setFilter(event.target.value)
              filterRows(event.target.value)
            }}
          >
            <option value="All">Filter By Complaint Status</option>
            <option value={LINKED}>Resolved</option>
            <option value={PENDING}>Pending</option>
          </select>
        </Col>
        <Col>
          <DateRangePicker
            onChange={dateRangeChange}
            isOpen={isOpen}
          />
        </Col>
        <Col> <h1 className='text-heading'>{startDate ? `Results from ${startDate} to ${endDate}` : ''}</h1></Col>
        <div style={{ textAlign: 'center', backgroundColor: 'whitesmoke', color: 'black', padding: "18px", fontSize: "25px" }}>Registrations</div>
        <InputGroup fullWidth size="Small" status="Info">
          <input
            type="text"
            placeholder="Search..."
            onChange={(event) => handleInputChange(event)}
          />
        </InputGroup>
        <DataTable
          title="Registrations"
          columns={tableColumns}
          data={rows}
          pagination
          paginationRowsPerPageOptions={[10, 20, 50, 100, 200, 500]}
          paginationPerPage={100}
          striped
          dense
          noHeader
          highlightOnHover
        />
      </>

    </>
  )
  return <>{isLoading ? <LoadingSpinner message='Loading Data for Registrations' /> : registration}</>;
}
